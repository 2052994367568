
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Home",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          intro: {
            heading:
              "<strong>La Casa in Campagna</strong> is a farmhouse immersed in the fertile lands of the lower Molise where a few km from the sea you can find refreshment for a holiday or a short break from everyday stress.",
            subHeading1:
              "This Molise house was born from a dream, to make Simplicity Great. The passion and the imagination to create dishes that contain flavors and smells typical of the lower Molise, nutritious and genuine foods that mother earth can give us, a culinary tradition handed down from parents to children happen with feeling, the unconditional desire to continue to manage a agricultural activity despite the enormous weight of the historical period in which we find ourselves. Challenging social phenomena such as the urbanization of fertile agricultural lands, the increasingly lively depopulation of our territories with the aim of enhancing our local and cultural heritage.",
            subHeading2:
              "Featuring a barbecue, terrace and restaurant, La Casa in Campagna is located in <strong>San Martino in Pensilis</strong>, 8 km from the Adriatic coast. Free private parking is available on site. The rooms have a private bathroom with free toiletries and a hairdryer. The restaurant serves products from the property's own farm and the surrounding area. La Casa in Campagna is a 25-minute drive from the <strong>Port of Termoli</strong>, where ferries leave for the <strong>Tremiti Islands</strong>. <strong>Campobasso</strong> is 50 km away and <strong>Termoli</strong> is 14 km away .",
          },
          banners: {
            camere: {
              title: "Our rooms",
              text:
                "Immersed in nature, our comfortable double rooms welcome you in a serene restorative rest.",
            },
            prodotti: {
              title: "Our products",
              text:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            ristorante: {
              title: "Restaurant",
              text:
                "La casa in campagna was born from a dream, to make Simplicity Great. The passion and the imagination to create dishes that contain flavors and smells typical of the lower Molise, nutritious and genuine foods that mother earth knows how to give us, a culinary tradition handed down from parents to children happen with feeling.",
            },
          },
        },
        it: {
          intro: {
            heading:
              "<strong>La Casa in Campagna</strong> è un agriturismo immerso nelle fertili terre del basso molise dove a pochi km dal mare potrete trovare ristoro per una vacanza o per una breve pausa dallo stress quotidiano.",
            subHeading1:
              "Questa casa molisana nasce da un sogno, rendere Grande la Semplicità. La passione e la fantasia di creare dei piatti che racchiudono sapori e odori tipici del basso molise, cibi nutrienti e genuini che madre terra sa regalarci, succedere con sentimento una tradizione culinaria tramandata da genitori a figli, la voglia incondizionata di continuare a gestire un'attività agricola nonostante l'enorme peso del periodo storico nel quale ci troviamo. Sfidare fenomeni sociali come l'urbanizzazione di terreni fertili di agricoltura, lo spopolamento sempre più vivo dei nostri territori con l'obiettivo di valorizzare il nostro patrimonio locale e culturale.",
            subHeading2:
              "Situata a <strong>San Martino in Pensilis</strong>, a 8 km dalla costa adriatica, La Casa in Campagna offre un barbecue, una terrazza, un ristorante e un parcheggio privato gratuito in loco. Le camere dispongono di bagno privato con set di cortesia e asciugacapelli. Il ristorante serve prodotti della fattoria della proprietà e della zona circostante. La Casa in Campagna vi attende a 25 minuti d'auto dal <strong>Porto di Termoli</strong>, da dove partono i traghetti per le <strong>Isole Tremiti</strong>, a 50 km da <strong>Campobasso</strong> e a 14 km da <strong>Termoli</strong>.",
          },
          banners: {
            camere: {
              title: "Le nostre camere",
              text:
                "Immerse nella natura le nostri confortevoli camere matrimoniali vi accolgono in un sereno riposo ristoratore.",
            },
            prodotti: {
              title: "I nostri prodotti",
              text:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            ristorante: {
              title: "Il nostro ristorante",
              text:
                "La Casa in Campagna nasce da un sogno, rendere Grande la Semplicità. La passione e la fantasia di creare dei piatti che racchiudono sapori e odori tipici del basso molise, cibi nutrienti e genuini che madre terra sa regalarci, succedere con sentimento una tradizione culinaria tramandata da genitori a figli.",
            },
          },
        },
      },
    });

    useMeta({
      title: "Home",
      description: `La Casa in Campagna nasce da un sogno, rendere Grande la Semplicità. La passione e la fantasia di creare dei piatti che racchiudono sapori e odori tipici del basso molise, cibi nutrienti e genuini che madre terra sa regalarci, succedere con sentimento una tradizione culinaria tramandata da genitori a figli.`,
    });
    return {
      t,
      ratingModel: ref(4.3),
      slide: ref(1),
      prodottiSlide: ref(0),
      ristoranteSlide: ref(0),
      camereSlide: ref(0),
      mainCarousellSlide: ref(1),
      autoplay: ref(true),
      reviews: [
        {
          text:
            "Il posto è molto tranquillo e silenzioso. Un po' fuori mano, ma sinceramente era quello che cercavamo. Si mangia anche bene. Buona anche la colazione.",
          value: 8,
        },
        {
          text: "Immersa nella campagna, estremamente tranquilla",
          value: 8,
        },
        {
          text:
            "Ottima la proposta della colazione cn prodotti tipici torte fatte in casa",
          value: 8,
        },
        {
          text:
            "Proprietario molto cortese e disponibile per ogni necessità.Agriturismo immerso nella campagna molisana a un quarto d'ora di macchina da Termoli. Posto estremamente tranquillo e silenzioso. Camera confortevole, pulita, e dotata di tutto l'essenziale.Buona colazione, con prodotti fatti in casa.",
          value: 8,
        },
      ],
      text: ref(""),
      ph: ref(""),
      dense: ref(false),
      controlType: ref("flat"),
      controlTypeOptions: [
        { value: "regular", label: "regular" },
        { value: "unelevated", label: "unelevated" },
        { value: "flat", label: "flat (default)" },
        { value: "outline", label: "outline" },
        { value: "push", label: "push" },
      ],
      mainCarousellImages: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393913/casa-in-campagna/others/12933165_493240974210891_2601823761317896533_n_zdc8mo.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393907/casa-in-campagna/others/18835530_688997927968527_3861205854233536527_n_rllbbi.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393925/casa-in-campagna/others/IMG_20210620_112054_sz1x7r.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393913/casa-in-campagna/others/18921767_688997914635195_9110139299291885974_n_iay82m.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393923/casa-in-campagna/others/IMG_20210620_105441_px8eet.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593902100/casa-in-campagna/others/204168170_gndrym.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593902101/casa-in-campagna/others/204168177_gql6eq.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593902103/casa-in-campagna/ristorante/45620492_gudu05.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393915/casa-in-campagna/ristorante/943974_482877385247250_6297779837002065984_n_irbacu.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393925/casa-in-campagna/ristorante/IMG_20210529_191257_ayznop.jpg",
      ],
      camere: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/fa0e6b60-a1f0-4bc0-8627-07ffba9ce3cd_xebbol.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/b25a75f8-5bf8-4841-9401-a93c3e25373c_dzin85.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/26cd2eec-2a31-4697-86d5-1f60f563ce81_q8nll0.jpg",
      ],
      ristorante: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393916/casa-in-campagna/ristorante/12809745_482877368580585_7588290578550315323_n_v7wde3.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393911/casa-in-campagna/ristorante/IMG_20210614_210102_uqgnvn.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393893/casa-in-campagna/ristorante/47032339_1006257982909185_4343558760690614272_o_ixyhvj.jpg",
      ],
      prodotti: [
        "https://images.unsplash.com/photo-1534099946341-34fe5ef39eef?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
        "https://images.unsplash.com/photo-1554307065-7a7ce307aa0c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80",
      ],
      slide5: ref("style"),
      lorem:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.",
    };
  },
});
